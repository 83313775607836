import React from 'react'
import '../Contact.css'

function Contact() {
  return (
    <section id="contact">
      <h1>Contact</h1>
      <p>Get in touch with us.</p>
    </section>
  )
}

export default Contact
